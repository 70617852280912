import 'intro.js/minified/introjs.min.css'
import introJs from 'intro.js'
import { setMultiEnviornmentCookie, getCookieValue, getCookies, removeCookie } from './views/common'

export const markIntroComplete = (name) => {
    setMultiEnviornmentCookie(`intro_${name}`, 1)
}
export const clearIntroComplete = (name) => {
    removeCookie(`intro_${name}`)
}
export const clearAllIntoComplete = () => {
    Object.keys(getCookies()).filter(key => key.startsWith("intro_")).forEach(key => removeCookie(key))
}
export const isIntroComplete = (name) => {
    return getCookieValue(`intro_${name}`) || getCookieValue("intro_all")
}

export const startDashboardIntro = (onExit = () => {}) => {
    const intro = introJs()
        .setOptions({
            exitOnOverlayClick: false,
            steps: [
                {
                    title: "The Dashboard Page",
                    intro: "Welcome to the Dashboard!<br><br>This will be the first thing you see when you open Glyue!"
                },
                {
                    element: "#dashboard-chart",
                    title: "Integration Overview",
                    intro: "On this chart, you can see how your intergrations have been performing.<br><br>The green line represents all your successful intergration runs, and the red line represents all of your failed integration runs."
                },
                {
                    element: "#dashboard-filters",
                    title: "Chart Filters",
                    intro: "These filters allow you to change the date range of the chart and select which integrations to include or exclude."
                },
                {
                    element: "#dashboard-download-report",
                    title: "Usage Report",
                    intro: "If you press this button, you'll download your usage report as an Excel document."
                },
                {
                    element: "#dashboard-validation-panel",
                    title: "Adapter Configs Validation Results",
                    intro: "On this panel, you'll see all of your configured adapter configs and how their most recent validation went."
                },
                {
                    element: "#validation-passed-icon",
                    title: "Validation Passed",
                    intro: "This icon indicates that the adapter config passed validation."
                },
                {
                    element: "#validation-unknown-icon",
                    title: "Validation Unknown",
                    intro: "This icon indicates that the adapter config has yet to be validated, and thus the result is unknown."
                },
                {
                    element: "#validation-failed-icon",
                    title: "Validation Failed",
                    intro: "This icon indicates that the adapter config failed validation."
                },
                {
                    element: "#error-dialog-btn",
                    title: "Error Message",
                    intro: "If the adapter errored, you can press this button to view the last error message."
                },
                {
                    element: "#validate-btn",
                    title: "Validate Your Configs",
                    intro: "Use this button to easily re-validate your adapter configs."
                },
                {
                    element: ".mdi-help-circle",
                    title: "Glyue Help Center",
                    intro: "When you press this button, you will be redirected to the Glyue Help Center web portal where you can submit tickets for Production/UAT Support, Field Mapping Changes, Glyue User Access, or Technical Support."
                },
                {
                    element: ".v-navigation-drawer",
                    title: "Navigation",
                    intro: "You can use the navigation pane to get to other pages within Glyue."
                },
                {
                    element: ".mdi-bookmark-multiple",
                    title: "Bookmark Page",
                    intro: "This button brings you to the bookmarks page.<br><br>Try going to the bookmarks page now."
                }
            ]
        })
        .onexit(() => {
            markIntroComplete("dashboard")
            onExit()
        })

    intro.start()
}

export const getBuildIntro = (onexit = () => {}) => {
    if(isIntroComplete("build")){return}
    return introJs().setOptions({
    disableInteraction:true,
    exitOnOverlayClick:false,
    steps:[
        {
            title: "The Build Page",
            intro:  "This page is where you can see each of the mappings between your systems.<br><br>" +
                    "Each row represents one piece of information that’s moved from the <b>source system</b> to the <b>target system.</b>"
        },
        {
            element:".hcell_source_field_name",
            title: "Mapping From...",
            intro:  "The <b>source field name</b> column is for the names of the fields you're mapping <b>from</b><br/><br/>" +
                    "In most cases, the source system will be your Loan Origination System, your Customer Relationship Management System, or wherever your data originates from."
        },
        {
            element:".hcell_target_field_name",
            title: "...Mapping To",
            intro: "The <b>target field name</b> column is for the names of the fields you're mapping <b>to</b><br/><br/>" +
                    "In most cases, the target system will be your banking core, or wherever your data is traveling to."
        },
        {
            element:'div[aria-colindex="1"]',
            title: "Comments",
            intro:  "Comments are used to provide mapping instructions and feedback to your engineer, and vice versa.<br><br>" +
                    "Try clicking here to see the comments."
        },
        {
            element:'div[aria-colindex="2"]',
            title: "Status",
            intro: "The Status field is used to indicate the current SDLC status of a single mapping.<br><br>" +
                    "The status of a mapping can be one of the following:<br><br>" +
                    "<ul><li>Not Started"+// - The mapping has not been started yet.<br>" +
                    "<li>In Progress"+// - The mapping is currently being worked on.<br>" +
                    "<li>Change Requested"+// - The mapping has been completed, but a change has been requested.<br>" +
                    "<li>Waiting for Support"+// - This mapping requires the attention of an SDE<br>" +
                    "<li>Waiting for Customer"+// - This mapping requires the attention of a customer<br>"
                    "<li>Approved</ul>"// - The mapping has been completed.<br>" +
        }
    ]
    }).onexit(() => {onexit(); markIntroComplete("build")})
}

export const getCommentIntro = () => {
    if(isIntroComplete("build-comment")){return}
    let steps = [{
        element: "#intro_comment",
        title:"Mapping Instruction",
        position: "left",
        intro:"Here is an example comment that you might make to your mapping engineer."
    },
    {
        element: "#intro_resolved",
        title: "Resolved Toggle",
        intro: "You can see that this comment was marked as 'resolved'<br><br>This means your comment has been addressed."
    },
    {
        element: "#intro_pane",
        title: "Comments Pane",
        intro: "Here you can see all of the comments for this mapping."
    },
    {
        element: "#intro_record",
        title:"Comment Records",
        intro: "For each comment you can see its author, the date it was posted, and whether it's been resolved."
    },
    {
        element: "#intro_new",
        title: "Create Comment",
        intro: "You can click here if you need to create a new comment."
    },
    {
        element: "#intro_form",
        position: "left",
        title: "Comment Form",
        intro: "Fill out this form if you need to add a new comment to the mapping."
    }]
    return introJs().setOptions({
        disableInteraction:true,
        exitOnOverlayClick:false,
        steps
    }).onexit(() => markIntroComplete("build-comment"))
}

export const getFakeComments = () => {
    return [
        {
            author_id:1,
            author_name: "Pretend You",
            content: "Could you please ensure that the customer name field is properly capitalized?",
            datetime: "2022-10-20T18:23:46.477000",
            modified_at: "2022-10-20T18:23:46.477000",
            id: 2,
            resolved: true,
            subject: "Capitalize Name"
        },
        {
            author_id:1,
            author_name: "The Engineer",
            content: "Fixed up that name field, first letter is now always capitalized.",
            datetime: "2022-10-20T18:23:46.477000",
            modified_at: "2022-10-20T18:23:46.477000",
            id: 3,
            resolved: true,
            subject: "Fixed: Capitalize Name"
        }
    ]
}

export const startBookmarkIntro = (onExit = () => {}) => {
    const intro = introJs()
        .setOptions({
            disableInteraction: true,
            exitOnOverlayClick: false,
            steps: [
                {
                    title: "Why We Bookmark",
                    intro: "Bookmarks break down your mappings into more manageable pieces."
                },
                {
                    element: "[data-cy=\"bookmark-folder\"]",
                    title: "Bookmark Folders",
                    intro: "Bookmarks are grouped into folders by category. You can click on one of these folders to see the bookmarks it contains."
                },
                {
                    element: "[data-cy=\"bookmark\"]",
                    title: "Bookmark",
                    intro: "Each bookmark has a title, and a brief description of the mappings it links to."
                },
                {
                    element: "[data-cy=\"bookmark-name\"]",
                    title: "Following the Bookmark",
                    intro: "You can click on the title of a bookmark to navigate to the relevant mappings.<br><br>As your integrations are implemented, more bookmarks will be created."
                }
            ]
        })
        .onexit(() => {
            markIntroComplete("bookmark")
            onExit()
        })

    intro.start()
}

export const getFakeBookmarkFolders = () => {
    return [{
        id: 1,
        name: "example_ncino_to_encompass",
        bookmarks: [{
            id: 20,
            name: "Reserve loan number",
            description: "Reserve a loan number, required for the rest of the integration to work.",
            external: false,
            section: 1,
            url: "/"
        }]
    }]
}

export const getFeedbackIntro = (onexit = () => {}) => {
    if(isIntroComplete("feedback")){return}
    return introJs().setOptions({
    disableInteraction: true,
    exitOnOverlayClick: false,
    steps:[
        {
            element: "textarea[data-cy='feedback-form-description']",
            title: "",
            intro:  "You can submit feedback or suggestions about Glyue in this text box. To send feedback, you must provide text in this box."
        },
        {
            element: ".mdi-paperclip",
            title: "",
            intro:  "This button will allow you to attach one or more files to your feedback."
        }
    ]
    }).onexit(() => {onexit(); markIntroComplete("feedback")})
}

/*

IMPLEMENTATION POSTPONED

export const getFakeRuns = () => {
    fakeItem = {
        "content":null,
        "content_user_provided":false,
        "end_timestamp":"2023-01-03T21:27:19.287812",
        "error_indicator":false,
        "id":329,
        "integration_run_history_id":130,
        "label":"large",
        "request":"{\n    \"service_name\": \"generate_payload\",\n    \"payload\": {\n        \"records\": \"X\"\n    },\n    \"sub_requests\": {}\n}",
        "response":"{\n    \"success\": true,\n    \"payload\": {\n        \"records\": \"X\"\n    },\n    \"messages\": []\n}",
        "stack_trace":null,
        "start_timestamp":"2023-01-03T21:27:19.273109",
        "type":"REQUEST"
    }
    fakeSteps = [
        {
            "id":328,
            "type":"INPUT",
            "label":"input",
            "start_timestamp":"2023-01-03T21:27:11.927608",
            "end_timestamp":"2023-01-03T21:27:11.927608",
            "error_indicator":false,
            "content_user_provided":false,
            "integration_run_history":130
        },{
            "id":329,
            "type":"REQUEST",
            "label":"large",
            "start_timestamp":"2023-01-03T21:27:19.273109",
            "end_timestamp":"2023-01-03T21:27:19.287812",
            "error_indicator":false,
            "content_user_provided":false,
            "integration_run_history":130
        },
        {
            "id":330,
            "type":"OUTPUT",
            "label":"output",
            "start_timestamp":"2023-01-03T21:27:19.302631",
            "end_timestamp":"2023-01-03T21:27:19.302631",
            "error_indicator":false,
            "content_user_provided":false,
            "integration_run_history":130
        }
    ]
    fakeRuns = {
        "totalRecords":2,"pages":1,
        "recordsPerPage":50,
        "data":[
            {"id":131,"error_indicator":true,"is_complete":true,"start_timestamp":"2023-01-03T21:28:35.298384","integration__path_name":"casync","username":"michaelm"},
            {"id":130,"error_indicator":false,"is_complete":true,"start_timestamp":"2023-01-03T21:27:11.927608","integration__path_name":"test_large_payload","username":"michaelm"}
        ],
        "integrations":["test_large_payload","noconfig","casync"],
        "users":["michaelm"],
        "searchReport":{}
    }
    return {fakeRuns, fakeSteps, fakeItem}
}
*/
