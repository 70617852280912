import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import vuetify from './plugins/vuetify'

import { keybindPlugin } from './keybinds'
import { metricsPlugin } from './metrics'
import { axiosPlugin } from './plugins/axios'
import GBtn from '@/components/GlyueButton.vue'
import GSelect from '@/components/GlyueSelect.vue'
import GCheckbox from '@/components/GlyueCheckbox.vue'

const pinia = createPinia()
const app = createApp(App)
app.use(router(pinia))
app.use(vuetify)
app.use(pinia)
app.use(metricsPlugin)
app.use(keybindPlugin)
app.use(axiosPlugin)
app.component('GBtn', GBtn)
app.component('GSelect', GSelect)
app.component('GCheckbox', GCheckbox)

app.mount('#app')
