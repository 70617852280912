// stores/user.js
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { DateTime } from 'luxon'

export const useInstallationStore = defineStore('installation', () => {
  const name = ref(null)
  const git = ref({
    branch: null,
    time: null,
    shortid: null,
    version: null,
  })

  const gitDate = computed(() =>
    git.value.time
      ? DateTime.fromSeconds(parseInt(git.value.time)).toLocaleString({ month: 'short', year: 'numeric' })
      : null
  )
  const jira_enabled = ref(null)
  const help_center_url = ref(null)
  const logo_src = ref(null)
  const catalogEnabled = ref(null)
  const sdlc_phase = ref(null)
  const require_integration_export_permission = ref(null)

  async function updateInstallation(data) {
    name.value = data.installation
    git.value = data.git
    jira_enabled.value = data.jira_enabled
    help_center_url.value = data.help_center_url
    logo_src.value = data.logo
    catalogEnabled.value = data.catalog_enabled
    sdlc_phase.value = data.sdlc_phase
    require_integration_export_permission.value = data.require_integration_export_permission
  }

  return {
    name,
    git,
    logo_src,
    jira_enabled,
    help_center_url,
    gitDate,
    sdlc_phase,
    catalogEnabled,
    require_integration_export_permission,
    updateInstallation,
  }
})
