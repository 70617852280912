import axios from 'axios'
import Cookies from 'js-cookie'

export const getCookieValue = (name) => {
  return Cookies.get(name)
}

export const getCookies = () => {
  return Cookies.get()
}

export const setCookie = (name,value,days) => {
  Cookies.set(name, value, {expires:days})
}

export const removeCookie = (name) => {
  let options = {}
  let hostname = window.location.hostname
  if (hostname != "localhost") {
    options.domain = hostname.includes("sandboxbanking.com") ? ".sandboxbanking.com" : hostname
  }
  Cookies.remove(name, options)
}

export const setMultiEnviornmentCookie = (name, value, options={}) => {
  options.expires = options.expires || 3650
  let hostname = window.location.hostname
  if (hostname != "localhost") {
    options.domain = hostname.includes("sandboxbanking.com") ? ".sandboxbanking.com" : hostname
  }
  options.sameSite = "strict"
  Cookies.set(name, value, options)
}

export const getSessionStorageValue = (key, defaultValue) => {
  const value = sessionStorage.getItem(key)

  if (value === null)
    return setSessionStorageValue(key, defaultValue)

  return value
}

export const setSessionStorageValue = (key, value) => {
  sessionStorage.setItem(key, value)
  return value
}

export const createFormBody = details => {
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  return formBody.join("&")
}

export const getProperTableName = (name) => {
  const tableNames = {
    integration: "Integration",
    servicerequest: "Service Request",
    fieldmapping: "Field Mapping",
    valuemappingset: "Value Mapping Set",
    valuemapping: "Value Mapping",
    validationrule: "Validation Rule",
    mask: "Mask",
    comment: "Comments"
  }
  return tableNames[name]
}

export const setDocumentTitlePage = (to, glyue_instance) => {
  let zone = 'Sandbox Banking'
  let instance = document.title.split(' | ').pop()
  if (glyue_instance && instance === `${glyue_instance} Glyue`){
    return
  }

  if (to.meta.title) {
    if (to.path === '/build' && to.query.table) {
      zone = getProperTableName(to.query.table)
    }
    else {
      zone = to.meta.title
    }
  }
  document.title = `${zone} | ${instance}`
}

export const commonSort = (array) => {
  function combinedSortCompare(a, b) {
    if(/^\d+$/.test(a) && /^\d+$/.test(b)) {
      return Number(a) - Number(b)
    } else {
      return String(a.toLowerCase()).localeCompare(String(b.toLowerCase()))
    }
  }
  return array.sort(combinedSortCompare)
}

export const backend = (url, method="GET", data=undefined) => {
  let opts = {
    url,
    method,
    credentials: "include",
  }
  if (method != "GET") {
    opts.headers = { "X-CSRFToken": getCookieValue('csrftoken')  }
    opts.data = data
  }

  return axios(opts)
}

/// Dates
export const timestampToDate = (timestamp) => {
  return new Date(Number(timestamp) * 1000)
} 

export const dateToUnixTimeStamp = (date) => {
  return Math.floor(date.getTime() / 1000)
}

export const datetimeToDate = (datetime) => {
  const initDate = new Date(datetime) // UTC datetime
  return new Date(initDate.getTime() - initDate.getTimezoneOffset()*60000)
}

export const formatDate = (date, options = {}) => {
  if (typeof date === "string") {
    date = datetimeToDate(date)
  }

  return Intl.DateTimeFormat('en-US',
    {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute:'numeric',
      second: 'numeric',
      timeZoneName: 'short',
      ...options
    }
  ).format(date)
}

export const escapeHTML = (str) => {
  return (String(str)).replace(/&/g,'&amp;').replace(/</g,'&lt;').replace(/>/g,'&gt;') ;
}

export function getBestFileIcon(filename) {
  const docTypes = ['.doc', '.docx', '.txt', 'rtf']
  const sheetTypes = ['.xls', '.xslx', '.csv']
  const imgTypes = ['.gif', '.tiff', '.bmp', '.jpg', '.jpeg', '.png']
  if (filename.endsWith('pdf')) return 'mdi-file-pdf-box'
  else if (docTypes.some(e => filename.endsWith(e))) return 'mdi-file-document'
  else if (sheetTypes.some(e => filename.endsWith(e))) return "mdi-file-table"
  else if (imgTypes.some(e => filename.endsWith(e))) return "mdi-file-image"
  else return "mdi-file-outline"
}

// Currently, `navigator.userAgentData` is only supported on Chromium browsers.
export const OS = (navigator.userAgentData?.platform ?? navigator.platform)
    .match(/win|mac|linux/i)[0]?.toLowerCase()

export const isWindows = OS === "win"
export const isMac = OS === "mac"
export const isLinux = OS === "linux"

const ALLOWED_PROTOCOLS = ["http", "https"]

export const toURL = (str, external = true) => {
  try {
    const url = new URL(str,
      !external
        ? window.location.origin
        : undefined
    )

    const protocol = url.protocol.slice(0, -1) // E.g., "http:" -> "http"

    if (ALLOWED_PROTOCOLS.includes(protocol)) {
      return url
    }

    return null
  } catch {
    return null
  }
}
