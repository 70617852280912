<template>
  <div>
    <v-snackbar
      v-for="notification in notifications"
      :key="notification.id"
      :class="toastClass"
      :color="notification.color"
      data-cy="glyue-notification"
      :location="notification.location ?? 'bottom right'"
      :model-value="notification.show"
      :timeout="-1"
      max-width="400px"
    >
      <div v-if="notification.msg">
        <v-icon
          v-if="notification.icon"
          start
        >
          {{ notification.icon }}
        </v-icon>
        {{ notification.msg }}
      </div>
      <div v-if="notification.link">
        <v-icon
          v-if="!notification.msg && notification.icon"
          start
        >
          {{ notification.icon }}
        </v-icon>
        <a
          class="text-primary flex-grow-1 flex-shrink-0"
          :href="notification.link.href"
          target="_blank"
          rel="noopener"
        >
          {{ notification.link.text }}
        </a>
      </div>
      <template #actions>
        <g-btn
          v-if="notification.showClose"
          density="comfortable"
          icon
          metric="toast/close"
          variant="text"
          @click="close(notification.id)"
        >
          <v-icon>mdi-close</v-icon>
        </g-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapStores } from "pinia"
import { useNotificationStore } from "../stores/notification"

export default {
  computed: {
    notifications() {
      return this.notificationStore.notifications
    },
    toastClass() {
      return this.$route.name === "build" || this.$route.name === "diff" ? "toast-container" : ""
    },
    ...mapStores(useNotificationStore),
  },
  methods: {
    close(id) {
      this.notificationStore.removeNotificationById(id)
    },
  },
}
</script>

<style scoped>
.toast-container {
  padding: 0px 12px 65px 56px !important;
}
</style>
